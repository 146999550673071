import React, { useState, useEffect } from 'react';
import styles from './Flights.module.css';
import { FaExchangeAlt, FaMapMarkerAlt, FaCalendarAlt, FaUser, FaPlus, FaPlane, FaSearch } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import PassengerClassSelect from '../components/PassengerClassSelect/PassengerClassSelect';
import { searchFlights, searchAirportsByCityOrCountry, searchLocalAirports } from '../services/amadeusService';
import { format } from 'date-fns';
import FlightResults from '../components/FlightResults/FlightResults';

const Flights = () => {
  const [tripType, setTripType] = useState('oneWay');
  const [departureDate, setDepartureDate] = useState(new Date());
  const [returnDate, setReturnDate] = useState(new Date());
  const [showPassengerSelect, setShowPassengerSelect] = useState(false);
  const [passengerInfo, setPassengerInfo] = useState({
    class: 'Economy',
    passengers: {
      adult: 1,
      child: 0,
      infant: 0
    }
  });
  const [multiCityFlights, setMultiCityFlights] = useState([
    { from: 'MLW - Monrovia, Liberia', to: 'ACC - Accra, Ghana', date: new Date() },
    { from: 'ACC - Accra, Ghana', to: 'Destination', date: new Date() }
  ]);
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');
  const [originSuggestions, setOriginSuggestions] = useState([]);
  const [destinationSuggestions, setDestinationSuggestions] = useState([]);
  const [searchResults, setSearchResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const fetchAirportSuggestions = async (keyword, setterFunction) => {
    // Start suggestions from 2 letters
    if (keyword.length < 2) {
      setterFunction([]);
      return;
    }

    try {
      // First try local search
      const localSuggestions = searchLocalAirports(keyword);
      
      if (localSuggestions.length > 0) {
        setterFunction(localSuggestions);
        return;
      }

      // Fallback to Amadeus API if no local results
      const apiSuggestions = await searchAirportsByCityOrCountry(keyword);
      
      if (apiSuggestions.length === 0) {
        console.warn(`No airport suggestions found for keyword: ${keyword}`);
      }
      
      setterFunction(apiSuggestions);
    } catch (err) {
      console.error('Error in fetchAirportSuggestions:', {
        keyword,
        error: err.message,
        stack: err.stack
      });
      
      // Optional: Set a user-friendly error message
      setterFunction([]);
    }
  };

  const debouncedOriginSuggestions = debounce(
    (keyword) => fetchAirportSuggestions(keyword, setOriginSuggestions), 
    300
  );

  const debouncedDestinationSuggestions = debounce(
    (keyword) => fetchAirportSuggestions(keyword, setDestinationSuggestions), 
    300
  );

  const handleOriginChange = (e) => {
    const value = e.target.value;
    setOrigin(value);
    debouncedOriginSuggestions(value);
  };

  const handleDestinationChange = (e) => {
    const value = e.target.value;
    setDestination(value);
    debouncedDestinationSuggestions(value);
  };

  const selectOriginSuggestion = (airport) => {
    setOrigin(`${airport.iataCode} - ${airport.city}, ${airport.country}`);
    setOriginSuggestions([]);
  };

  const selectDestinationSuggestion = (airport) => {
    setDestination(`${airport.iataCode} - ${airport.city}, ${airport.country}`);
    setDestinationSuggestions([]);
  };

  const addNewCity = () => {
    setMultiCityFlights([
      ...multiCityFlights,
      { from: 'Origin', to: 'Destination', date: new Date() }
    ]);
  };

  const handlePassengerChange = (info) => {
    setPassengerInfo(info);
  };

  const getPassengerDisplayText = () => {
    const total = Object.values(passengerInfo.passengers).reduce((a, b) => a + b, 0);
    return `${total} Traveller${total > 1 ? 's' : ''}, ${passengerInfo.class}`;
  };

  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    try {
      let originCode = origin.split(' - ')[0];
      let destinationCode = destination.split(' - ')[0];
      
      const searchParams = {
        originLocationCode: originCode,
        destinationLocationCode: destinationCode,
        departureDate: format(departureDate, 'yyyy-MM-dd'),
        adults: passengerInfo.passengers.adult,
        returnDate: tripType === 'roundTrip' ? format(returnDate, 'yyyy-MM-dd') : undefined
      };

      const results = await searchFlights(searchParams);
      setSearchResults(results);
    } catch (err) {
      console.error('Error searching flights:', err);
      setError(err.message || 'Failed to search flights');
    } finally {
      setLoading(false);
    }
  };

  const handleMultiCityChange = (index, field, value) => {
    const updatedFlights = [...multiCityFlights];
    updatedFlights[index] = {
      ...updatedFlights[index],
      [field]: value
    };
    setMultiCityFlights(updatedFlights);
  };

  const renderDateInputs = () => {
    if (tripType === 'roundTrip') {
      return (
        <>
          <div className={styles.inputGroup}>
            <div className={styles.inputLabel}>
              <FaCalendarAlt className={styles.icon} />
              <span>Departure</span>
            </div>
            <DatePicker
              selected={departureDate}
              onChange={(date) => setDepartureDate(date)}
              dateFormat="d MMM yyyy"
              minDate={new Date()}
              className={styles.datePicker}
            />
          </div>

          <div className={styles.inputGroup}>
            <div className={styles.inputLabel}>
              <FaCalendarAlt className={styles.icon} />
              <span>Return</span>
            </div>
            <DatePicker
              selected={returnDate}
              onChange={(date) => setReturnDate(date)}
              dateFormat="d MMM yyyy"
              minDate={departureDate}
              className={styles.datePicker}
            />
          </div>
        </>
      );
    } else if (tripType === 'oneWay') {
      return (
        <div className={styles.inputGroup}>
          <div className={styles.inputLabel}>
            <FaCalendarAlt className={styles.icon} />
            <span>Departure</span>
          </div>
          <DatePicker
            selected={departureDate}
            onChange={(date) => setDepartureDate(date)}
            dateFormat="d MMM yyyy"
            minDate={new Date()}
            className={styles.datePicker}
          />
        </div>
      );
    }
    return null;
  };

  const renderMultiCityInputs = () => {
    return (
      <div>
        {multiCityFlights.map((flight, index) => (
          <div key={index} className={styles.multiCityRow}>
            <div className={styles.inputGroup}>
              <div className={styles.inputLabel}>
                <FaMapMarkerAlt className={styles.icon} />
                <span>From</span>
              </div>
              <input
                type="text"
                placeholder="City or Airport"
                value={flight.from}
                onChange={(e) => handleMultiCityChange(index, 'from', e.target.value)}
              />
            </div>

            <button 
              className={styles.exchangeButton}
              onClick={() => {
                const updatedFlights = [...multiCityFlights];
                const temp = updatedFlights[index].from;
                updatedFlights[index].from = updatedFlights[index].to;
                updatedFlights[index].to = temp;
                setMultiCityFlights(updatedFlights);
              }}
            >
              <FaExchangeAlt />
            </button>

            <div className={styles.inputGroup}>
              <div className={styles.inputLabel}>
                <FaMapMarkerAlt className={styles.icon} />
                <span>To</span>
              </div>
              <input
                type="text"
                placeholder="City or Airport"
                value={flight.to}
                onChange={(e) => handleMultiCityChange(index, 'to', e.target.value)}
              />
            </div>

            {index === multiCityFlights.length - 1 && (
              <button className={styles.searchButton} onClick={handleSearch}>
                <FaSearch className={styles.searchIcon} />
                Search
              </button>
            )}
          </div>
        ))}
        <button
          type="button"
          className={styles.addCityButton}
          onClick={addNewCity}
        >
          <FaPlus /> Add Another City
        </button>
      </div>
    );
  };

  const renderAirportSuggestions = (suggestions, onSelect) => {
    if (!suggestions.length) return null;

    return (
      <div className={styles.suggestionsContainer}>
        {suggestions.map((airport, index) => (
          <div
            key={index}
            className={styles.suggestionItem}
            onClick={() => onSelect(airport)}
          >
            <div className={styles.airportIcon}>
              <FaPlane />
            </div>
            <div className={styles.airportInfo}>
              <div className={styles.airportName}>{airport.name}</div>
              <div className={styles.airportLocation}>{airport.city}, {airport.country}</div>
            </div>
            <div className={styles.airportCode}>
              {airport.iataCode}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderLocationInputs = () => {
    return (
      <div className={styles.locationInputs}>
        <div className={styles.inputGroup}>
          <div className={styles.inputLabel}>
            <FaMapMarkerAlt className={styles.icon} />
            <span>From</span>
          </div>
          <input 
            type="text" 
            value={origin} 
            onChange={handleOriginChange} 
            placeholder="City or Airport" 
          />
          {renderAirportSuggestions(originSuggestions, selectOriginSuggestion)}
        </div>
        
        <button 
          className={styles.exchangeButton}
          onClick={() => {
            const temp = origin;
            setOrigin(destination);
            setDestination(temp);
          }}
        >
          <FaExchangeAlt />
        </button>

        <div className={styles.inputGroup}>
          <div className={styles.inputLabel}>
            <FaMapMarkerAlt className={styles.icon} />
            <span>To</span>
          </div>
          <input 
            type="text" 
            value={destination} 
            onChange={handleDestinationChange} 
            placeholder="City or Airport" 
          />
          {renderAirportSuggestions(destinationSuggestions, selectDestinationSuggestion)}
        </div>

        <button className={styles.searchButton} onClick={handleSearch}>
          <FaSearch className={styles.searchIcon} />
          Search
        </button>
      </div>
    );
  };

  return (
    <div className={styles.flightsContainer}>
      <div className={styles.searchBox}>
        <div className={styles.tripTypes}>
          <button 
            className={`${styles.tripTypeBtn} ${tripType === 'roundTrip' ? styles.active : ''}`}
            onClick={() => setTripType('roundTrip')}
          >
            Round Trip
          </button>
          <button 
            className={`${styles.tripTypeBtn} ${tripType === 'oneWay' ? styles.active : ''}`}
            onClick={() => setTripType('oneWay')}
          >
            One Way
          </button>
          <button 
            className={`${styles.tripTypeBtn} ${tripType === 'multiCity' ? styles.active : ''}`}
            onClick={() => setTripType('multiCity')}
          >
            Multi City
          </button>
        </div>

        {tripType === 'multiCity' ? (
          renderMultiCityInputs()
        ) : (
          <>
            {renderLocationInputs()}
            <div className={styles.datePassengerInputs}>
              {renderDateInputs()}
              <div className={styles.inputGroup} style={{ position: 'relative' }}>
                <div className={styles.inputLabel}>
                  <FaUser className={styles.icon} />
                  <span>Passengers and Class</span>
                </div>
                <div 
                  className={styles.inputWrapper}
                  onClick={() => setShowPassengerSelect(!showPassengerSelect)}
                >
                  <input 
                    type="text" 
                    readOnly 
                    value={getPassengerDisplayText()}
                    placeholder="1 Traveller, Economy"
                  />
                </div>
                {showPassengerSelect && (
                  <PassengerClassSelect
                    onClose={() => setShowPassengerSelect(false)}
                    onChange={handlePassengerChange}
                  />
                )}
              </div>
            </div>
          </>
        )}

        <div className={styles.advancedOptions}>
          <button className={styles.advancedBtn}>
            Advanced search options
          </button>
          <div className={styles.filterOptions}>
            <label className={styles.checkbox}>
              <input type="checkbox" /> Direct Flights
            </label>
            <label className={styles.checkbox}>
              <input type="checkbox" /> Refundable
            </label>
            <input 
              type="text" 
              placeholder="Preferred Airline"
              className={styles.airlineInput}
            />
          </div>
        </div>

        {(searchResults || loading || error) && (
          <FlightResults
            results={searchResults}
            loading={loading}
            error={error}
            searchParams={{
              originLocationCode: origin,
              destinationLocationCode: destination,
              departureDate: format(departureDate, 'MMM dd, yyyy'),
              returnDate: returnDate ? format(returnDate, 'MMM dd, yyyy') : null,
              tripType,
              adults: passengerInfo.passengers.adult
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Flights;
