import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './FlightResults.module.css';
import FlightDetails from '../FlightDetails/FlightDetails';

const FlightResults = ({ results, searchParams, loading, error }) => {
  console.log('FlightResults props:', { results, searchParams, loading, error });

  const [selectedFlight, setSelectedFlight] = useState(null);
  const navigate = useNavigate();

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loadingSpinner}></div>
        <p>Searching for the best flights...</p>
      </div>
    );
  }

  if (error) {
    return <div className={styles.errorMessage}>{error}</div>;
  }

  if (!results || results.length === 0) {
    return (
      <div className={styles.noResults}>
        <h2>No flights found</h2>
        <p>Try adjusting your search parameters or checking back later for more options.</p>
      </div>
    );
  }

  const formatDuration = (duration) => {
    return duration.replace('PT', '')
      .toLowerCase()
      .replace('h', 'h ')
      .replace('m', 'm');
  };

  const formatTime = (dateString) => {
    return new Date(dateString).toLocaleTimeString([], { 
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
  };

  return (
    <div className={styles.resultsContainer}>
      <div className={styles.resultsHeader}>
        <div className={styles.routeInfo}>
          <h2>
            {searchParams.originLocationCode} to {searchParams.destinationLocationCode}
            {searchParams.tripType === 'roundTrip' && ' (Round Trip)'}
          </h2>
          <p>{searchParams.departureDate} • {searchParams.adults} Traveler{searchParams.adults !== 1 ? 's' : ''}</p>
        </div>
        <button className={styles.editSearch}>Edit Search</button>
      </div>

      {results.map((flight, index) => (
        <div key={index} className={styles.flightCard}>
          {flight.itineraries.map((itinerary, itineraryIndex) => (
            <div key={itineraryIndex}>
              <div className={styles.flightInfo}>
                <div className={styles.airline}>
                  <div className={styles.flightNumber}>
                    {itinerary.segments[0].carrierCode} {itinerary.segments[0].number}
                  </div>
                  {itinerary.segments[0].operating && (
                    <div className={styles.operatedBy}>
                      Operated by {itinerary.segments[0].operating.carrierCode}
                    </div>
                  )}
                </div>

                <div className={styles.timeInfo}>
                  <div className={styles.departure}>
                    <strong>{formatTime(itinerary.segments[0].departure.at)}</strong>
                    <span>{itinerary.segments[0].departure.iataCode}</span>
                  </div>

                  <div className={styles.flightPath}>
                    {itinerary.segments.length > 1 && (
                      <div className={styles.stopInfo}>
                        {itinerary.segments.length - 1} Stop{itinerary.segments.length > 2 ? 's' : ''} via {
                          itinerary.segments.slice(0, -1).map(segment => segment.arrival.iataCode).join(', ')
                        }
                      </div>
                    )}
                    <span className={styles.duration}>{formatDuration(itinerary.duration)}</span>
                    <div className={styles.pathLine}></div>
                    <span className={styles.stops}>
                      {itinerary.segments.length === 1 ? 'Direct' : `${itinerary.segments.length - 1} Stop${itinerary.segments.length > 2 ? 's' : ''}`}
                    </span>
                  </div>

                  <div className={styles.arrival}>
                    <strong>{formatTime(itinerary.segments[itinerary.segments.length - 1].arrival.at)}</strong>
                    <span>{itinerary.segments[itinerary.segments.length - 1].arrival.iataCode}</span>
                  </div>
                </div>

                <div className={styles.fareInfo}>
                  <div className={styles.price}>
                    <strong>USD {flight.price.total}</strong>
                    <span className={styles.refundable}>
                      {flight.pricingOptions?.fareType?.includes('REFUNDABLE') ? 'Refundable' : 'Non Refundable'}
                    </span>
                  </div>
                  <button 
                    className={styles.selectFlight}
                    onClick={() => navigate('/booking-details', { state: { flight } })}
                  >
                    Select flight
                  </button>
                  <button 
                    className={styles.flightDetails}
                    onClick={() => setSelectedFlight(flight)}
                  >
                    Flight details
                  </button>
                </div>
              </div>
              {itineraryIndex === 0 && flight.tripType === 'roundTrip' && flight.itineraries.length > 1 && (
                <div className={styles.returnLabel}>Return Flight</div>
              )}
            </div>
          ))}
        </div>
      ))}

      {selectedFlight && (
        <FlightDetails
          flight={selectedFlight}
          isOpen={!!selectedFlight}
          onClose={() => setSelectedFlight(null)}
        />
      )}
    </div>
  );
};

export default FlightResults;
