import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';

const Container = styled.div`
  max-width: 600px;
  margin: 120px auto 40px;
  padding: 40px;
  text-align: center;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const SuccessIcon = styled.div`
  color: #28a745;
  font-size: 64px;
  margin-bottom: 24px;
`;

const Title = styled.h1`
  color: #333;
  margin-bottom: 16px;
`;

const Message = styled.p`
  color: #666;
  font-size: 18px;
  margin-bottom: 32px;
`;

const Button = styled.button`
  background: #4682B4;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background: #3a6d96;
  }
`;

const PaymentSuccess = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleReturnHome = () => {
    navigate('/');
  };

  return (
    <Container>
      <SuccessIcon>
        <FaCheckCircle />
      </SuccessIcon>
      <Title>Payment Successful!</Title>
      <Message>
        Thank you for booking with Sandy Travel Tours. Your payment has been processed successfully.
        A confirmation email with your booking details will be sent to you shortly.
      </Message>
      <Button onClick={handleReturnHome}>Return to Home</Button>
    </Container>
  );
};

export default PaymentSuccess;
