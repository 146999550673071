import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Hero from '../components/Hero/Hero';
import { colors } from '../styles/colors';
import { destinationImages } from '../assets/images';
import { FaPlane, FaUmbrellaBeach, FaHotel } from 'react-icons/fa';

const Section = styled.section`
  padding: 4rem 2rem;
  background-color: ${props => props.background || colors.primary};
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const SectionTitle = styled.h2`
  color: ${colors.text};
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const ServicesPreview = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const ServiceCard = styled.div`
  background: ${colors.secondary};
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
  width: 75%;
  margin: 0 auto;

  &:hover {
    transform: translateY(-5px);
  }
`;

const AboutPreview = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
`;

const DestinationsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
`;

const DestinationCard = styled.div`
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  height: 300px;
  cursor: pointer;

  &:hover img {
    transform: scale(1.1);
  }
`;

const DestinationImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
`;

const DestinationInfo = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  background: linear-gradient(transparent, rgba(0,0,0,0.8));
  color: ${colors.primary};
`;

const CTASection = styled.div`
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
`;

const CTAButton = styled(Link)`
  display: inline-block;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: white;
  color: red;
  border: 2px solid red;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: red;
    color: white;
  }
`;

const StartPlanningButton = styled(Link)`
  display: inline-block;
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: white;
  color: red;
  border: 2px solid red;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: red;
    color: white;
  }
`;

const MainContent = styled.main`
  position: relative;
  z-index: 1;
  background: ${colors.primary};
`;

const destinations = [
  { name: 'Bali', image: destinationImages.bali },
  { name: 'Paris', image: destinationImages.paris },
  { name: 'Santorini', image: destinationImages.santorini },
  { name: 'Maldives', image: destinationImages.maldives }
];

const Home = () => {
  return (
    <div>
      <Hero />
      <MainContent>
        <Section>
          <Container>
            <SectionTitle>About Us</SectionTitle>
            <AboutPreview>
              <p>
                Sandy Travel and Tourism Agency is efficient in delivering travel services in and out of Monrovia, Liberia, and was established in 2024. We provide customers with tailor-made services with particular emphasis on providing product solutions that are ideal.
              </p>
              <CTAButton to="/about">Read More About Us</CTAButton>
            </AboutPreview>
          </Container>
        </Section>

        <Section>
          <Container>
            <SectionTitle>Popular Destinations</SectionTitle>
            <DestinationsGrid>
              {destinations.map((destination) => (
                <DestinationCard key={destination.name}>
                  <DestinationImage src={destination.image} alt={destination.name} />
                  <DestinationInfo>
                    <h3>{destination.name}</h3>
                    <p>Discover the magic</p>
                  </DestinationInfo>
                </DestinationCard>
              ))}
            </DestinationsGrid>
          </Container>
        </Section>

        <Section>
          <Container>
            <SectionTitle>Our Services</SectionTitle>
            <ServicesPreview>
              <ServiceCard>
                <FaPlane style={{ fontSize: '2rem', color: 'red' }} />
                <h3 style={{ color: 'white' }}>Air Tickets</h3>
                <p style={{ color: 'white' }}>Find the best deals on flights to your dream destinations.</p>
              </ServiceCard>
              <ServiceCard>
                <FaUmbrellaBeach style={{ fontSize: '2rem', color: 'red' }} />
                <h3 style={{ color: 'white' }}>Holiday Packages</h3>
                <p style={{ color: 'white' }}>Experience unforgettable vacations with our curated holiday packages.</p>
              </ServiceCard>
              <ServiceCard>
                <FaHotel style={{ fontSize: '2rem', color: 'red' }} />
                <h3 style={{ color: 'white' }}>International Hotel Reservations</h3>
                <p style={{ color: 'white' }}>Find your perfect stay with our extensive network of hotels.</p>
              </ServiceCard>
            </ServicesPreview>
            <CTAButton to="/services">View Our Services</CTAButton>
          </Container>
        </Section>

        <Section background={colors.secondary}>
          <Container>
            <CTASection>
              <SectionTitle style={{ color: colors.primary }}>Ready to Start Your Journey?</SectionTitle>
              <p style={{ color: colors.primary }}>
                Let us help you plan your perfect getaway. Book now and get exclusive deals!
              </p>
              <StartPlanningButton to="/contact">Start Planning Today</StartPlanningButton>
            </CTASection>
          </Container>
        </Section>
      </MainContent>
    </div>
  );
};

export default Home;
