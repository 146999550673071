import React, { useState } from 'react';
import styles from './FlightDetails.module.css';

const FlightDetails = ({ flight, isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState('itinerary');

  if (!isOpen) return null;

  const formatTime = (dateString) => {
    return new Date(dateString).toLocaleTimeString([], { 
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    });
  };

  const formatDuration = (duration) => {
    // Check if duration is already in the correct format
    if (typeof duration === 'string' && duration.startsWith('PT')) {
      return duration.replace('PT', '')
        .toLowerCase()
        .replace('h', 'h ')
        .replace('m', 'm');
    }
    
    // If duration is a number (milliseconds), convert to hours and minutes
    if (typeof duration === 'number') {
      const hours = Math.floor(duration / (1000 * 60 * 60));
      const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
      return `${hours}h ${minutes}m`;
    }

    // If duration is undefined or invalid, return a placeholder
    return 'Duration N/A';
  };

  const calculateLayoverDuration = (arrivalTime, departureTime) => {
    const arrival = new Date(arrivalTime);
    const departure = new Date(departureTime);
    const durationMs = departure - arrival;
    return formatDuration(durationMs);
  };

  const renderItinerary = () => {
    return flight.itineraries.map((itinerary, index) => (
      <div key={index} className={styles.itinerarySegment}>
        <div className={styles.itineraryHeader}>
          <h3>
            {index === 0 ? 'Outbound' : 'Return'} • {formatDate(itinerary.segments[0].departure.at)}
          </h3>
          <div className={styles.totalDuration}>
            Total Duration: {formatDuration(itinerary.duration)}
          </div>
        </div>

        {itinerary.segments.map((segment, segIndex) => (
          <div key={segIndex} className={styles.segment}>
            <div className={styles.airline}>
              <div className={styles.airlineInfo}>
                <strong>{segment.carrierCode} {segment.number}</strong>
                <span>Airbus {segment.aircraft.code}</span>
                {segment.operating && (
                  <span className={styles.operatedBy}>
                    Operated by {segment.operating.carrierCode}
                  </span>
                )}
              </div>
            </div>

            <div className={styles.flightTimes}>
              <div className={styles.departure}>
                <div className={styles.time}>{formatTime(segment.departure.at)}</div>
                <div className={styles.airport}>
                  <strong>{segment.departure.iataCode}</strong>
                  <div>{segment.departure.terminal && `Terminal ${segment.departure.terminal}`}</div>
                </div>
              </div>

              <div className={styles.flightDuration}>
                <div className={styles.durationLine}>
                  <span className={styles.duration}>
                    {formatDuration(segment.duration)}
                  </span>
                </div>
              </div>

              <div className={styles.arrival}>
                <div className={styles.time}>{formatTime(segment.arrival.at)}</div>
                <div className={styles.airport}>
                  <strong>{segment.arrival.iataCode}</strong>
                  <div>{segment.arrival.terminal && `Terminal ${segment.arrival.terminal}`}</div>
                </div>
              </div>
            </div>

            {segIndex < itinerary.segments.length - 1 && (
              <div className={styles.layover}>
                Layover: {calculateLayoverDuration(
                  segment.arrival.at,
                  itinerary.segments[segIndex + 1].departure.at
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    ));
  };

  const renderFareRules = () => (
    <div className={styles.fareRules}>
      <div className={styles.ruleSection}>
        <h4>Cancellation</h4>
        <p>Cancellation fee may apply. Please contact airline for details.</p>
      </div>
      <div className={styles.ruleSection}>
        <h4>Changes</h4>
        <p>Change fee may apply. Please contact airline for details.</p>
      </div>
      <div className={styles.ruleSection}>
        <h4>No Show</h4>
        <p>No show fee may apply. Please contact airline for details.</p>
      </div>
    </div>
  );

  const renderBaggage = () => (
    <div className={styles.baggage}>
      <div className={styles.baggageSection}>
        <h4>Cabin Baggage</h4>
        <p>1 piece up to 7 kg</p>
      </div>
      <div className={styles.baggageSection}>
        <h4>Checked Baggage</h4>
        <p>2 pieces up to 23 kg each</p>
      </div>
    </div>
  );

  const renderFareBreakup = () => (
    <div className={styles.fareBreakup}>
      <div className={styles.fareItem}>
        <span>Base Fare</span>
        <span>USD {(parseFloat(flight.price.total) * 0.8).toFixed(2)}</span>
      </div>
      <div className={styles.fareItem}>
        <span>Taxes & Fees</span>
        <span>USD {(parseFloat(flight.price.total) * 0.2).toFixed(2)}</span>
      </div>
      <div className={`${styles.fareItem} ${styles.total}`}>
        <span>Total</span>
        <span>USD {flight.price.total}</span>
      </div>
    </div>
  );

  return (
    <>
      <div className={styles.overlay} onClick={onClose}></div>
      <div className={styles.modal}>
        <button className={styles.closeButton} onClick={onClose}>×</button>
        
        <div className={styles.tabs}>
          <button 
            className={`${styles.tab} ${activeTab === 'itinerary' ? styles.active : ''}`}
            onClick={() => setActiveTab('itinerary')}
          >
            Flight Itinerary
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'fareRules' ? styles.active : ''}`}
            onClick={() => setActiveTab('fareRules')}
          >
            Fare Rules
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'baggage' ? styles.active : ''}`}
            onClick={() => setActiveTab('baggage')}
          >
            Baggage
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'fareBreakup' ? styles.active : ''}`}
            onClick={() => setActiveTab('fareBreakup')}
          >
            Fare Breakup
          </button>
        </div>

        <div className={styles.content}>
          {activeTab === 'itinerary' && renderItinerary()}
          {activeTab === 'fareRules' && renderFareRules()}
          {activeTab === 'baggage' && renderBaggage()}
          {activeTab === 'fareBreakup' && renderFareBreakup()}
        </div>
      </div>
    </>
  );
};

export default FlightDetails;
