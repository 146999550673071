import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 120px auto 40px;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 30px;
  background: #f5f7fa;
  min-height: calc(100vh - 160px);
`;

const MainContent = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 30px;
`;

const SideContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TravellerSection = styled.div`
  margin-bottom: 30px;
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  color: #333;
  font-size: 18px;
  font-weight: 500;

  svg {
    color: #4682B4;
  }
`;

const FormContainer = styled.div`
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
`;

const FormRow = styled.div`
  display: grid;
  grid-template-columns: ${props => props.columns || '1fr 1fr'};
  gap: 20px;
  margin-bottom: 20px;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: #666;
  font-size: 14px;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
`;

const FlightSummary = styled.div`
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
`;

const SummaryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;

  h2 {
    margin: 0;
    font-size: 18px;
    color: #333;
  }
`;

const FlightDetailsButton = styled.button`
  background: #f0f2f8;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  color: #4682B4;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background: #e6e9f0;
  }
`;

const FareDetails = styled.div`
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);

  h2 {
    margin: 0 0 20px;
    font-size: 18px;
    color: #333;
  }
`;

const FareRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: ${props => props.total ? '2px solid #eee' : '1px solid #eee'};
  font-weight: ${props => props.total ? '600' : 'normal'};
  font-size: ${props => props.total ? '18px' : '16px'};

  &:last-child {
    border-bottom: none;
  }
`;

const ContinueButton = styled.button`
  background: #4682B4;
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background: #3a6d96;
  }
`;

const BackButton = styled.button`
  background: #f0f2f8;
  border: none;
  padding: 12px 24px;
  border-radius: 4px;
  color: #4682B4;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 30px;

  &:hover {
    background: #e6e9f0;
  }
`;

const BookingDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { flight } = location.state || {};
  const [formData, setFormData] = useState({
    title: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    email: '',
    phone: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // For testing, we'll navigate to payment regardless of form completion
    navigate('/payment', {
      state: {
        flight: {
          origin: flight.itineraries[0].segments[0].departure.iataCode,
          destination: flight.itineraries[0].segments[flight.itineraries[0].segments.length - 1].arrival.iataCode,
          departureDate: flight.itineraries[0].segments[0].departure.at,
          price: flight.price.total,
        },
        passengers: formData
      }
    });
  };

  if (!flight) {
    return <div>No flight selected. Please go back and select a flight.</div>;
  }

  return (
    <PageContainer>
      <MainContent>
        <BackButton onClick={() => navigate(-1)}>
          ← Back to Flight Selection
        </BackButton>

        <TravellerSection>
          <SectionTitle>
            <i className="fas fa-user"></i>
            Enter traveller details
          </SectionTitle>
          
          <FormContainer>
            <div>Enter your details as they appear on your passport. Use English only.</div>
            
            <form onSubmit={handleSubmit}>
              <FormRow columns="100px 1fr 1fr">
                <FormGroup>
                  <Label>Title*</Label>
                  <Select name="title" value={formData.title} onChange={handleInputChange}>
                    <option value="">Select</option>
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Ms">Ms</option>
                  </Select>
                </FormGroup>
                <FormGroup>
                  <Label>First Name*</Label>
                  <Input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Last Name*</Label>
                  <Input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </FormRow>

              <FormRow>
                <FormGroup>
                  <Label>Date of Birth*</Label>
                  <Input
                    type="date"
                    name="dateOfBirth"
                    value={formData.dateOfBirth}
                    onChange={handleInputChange}
                  />
                </FormGroup>
              </FormRow>
            </form>
          </FormContainer>
        </TravellerSection>

        <TravellerSection>
          <SectionTitle>
            <i className="fas fa-envelope"></i>
            Enter contact details
          </SectionTitle>
          
          <FormContainer>
            <FormRow columns="1fr">
              <FormGroup>
                <Label>Email*</Label>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </FormRow>
            <FormRow columns="1fr">
              <FormGroup>
                <Label>Phone Number*</Label>
                <Input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </FormRow>
          </FormContainer>
        </TravellerSection>
      </MainContent>

      <SideContent>
        <FlightSummary>
          <SummaryHeader>
            <h2>Flight summary</h2>
            <FlightDetailsButton>Flight details</FlightDetailsButton>
          </SummaryHeader>
          <div>
            <strong>{flight.itineraries[0].segments[0].departure.iataCode} to {flight.itineraries[0].segments[flight.itineraries[0].segments.length - 1].arrival.iataCode}</strong>
            <p>{new Date(flight.itineraries[0].segments[0].departure.at).toLocaleDateString()}</p>
          </div>
        </FlightSummary>

        <FareDetails>
          <h2>Fare details</h2>
          <FareRow>
            <span>Base Fare</span>
            <span>USD {flight.price.total}</span>
          </FareRow>
          <FareRow>
            <span>Taxes & Fees</span>
            <span>USD {(parseFloat(flight.price.total) * 0.2).toFixed(2)}</span>
          </FareRow>
          <FareRow total>
            <span>Total Fare</span>
            <span>USD {flight.price.total}</span>
          </FareRow>
        </FareDetails>

        <ContinueButton onClick={handleSubmit}>
          Continue to payment
        </ContinueButton>
      </SideContent>
    </PageContainer>
  );
};

export default BookingDetails;
