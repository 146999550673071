// Hero Images
import heroBg from './hero/hero-bg.jpg';

// Destination Images
import bali from './destinations/bali.png';
import paris from './destinations/paris.png';
import santorini from './destinations/santorini.png';
import maldives from './destinations/maldives.png';

export const heroImages = {
    background: heroBg
};

export const destinationImages = {
    bali,
    paris,
    santorini,
    maldives
};

// Export all images
export default {
    hero: heroImages,
    destinations: destinationImages
};
