import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaLock, FaShieldAlt, FaCreditCard } from 'react-icons/fa';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

// Initialize Stripe
const stripePromise = loadStripe('your_publishable_key'); // Replace with your Stripe publishable key

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 120px auto 40px;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const MainContent = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 30px;
`;

const SideContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const PaymentTitle = styled.h2`
  color: #333;
  margin-bottom: 24px;
`;

const CardElementContainer = styled.div`
  margin: 20px 0;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: white;

  .StripeElement {
    width: 100%;
    padding: 10px;
  }
`;

const ErrorMessage = styled.div`
  color: #dc3545;
  margin-top: 10px;
  font-size: 14px;
`;

const PaymentButton = styled.button`
  background: #4682B4;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 16px 32px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background: #3a6d96;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const SecurityFeatures = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #eee;
`;

const SecurityItem = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 8px;
`;

const SecurityIcon = styled.div`
  color: #4682B4;
  font-size: 24px;
`;

const SecurityText = styled.div`
  h4 {
    margin: 0;
    color: #333;
    font-size: 16px;
  }
  p {
    margin: 4px 0 0;
    color: #666;
    font-size: 14px;
  }
`;

const FlightSummaryCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const SummaryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;

  h2 {
    margin: 0;
    font-size: 20px;
    color: #333;
  }
`;

const FlightDetails = styled.div`
  margin-bottom: 20px;
`;

const CheckoutForm = ({ amount, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);
    setError(null);

    try {
      // Create payment method
      const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });

      if (stripeError) {
        setError(stripeError.message);
        setProcessing(false);
        return;
      }

      // Here you would typically make a call to your backend to process the payment
      // For demo purposes, we'll simulate a successful payment
      console.log('Payment Method:', paymentMethod);
      onSuccess();
      
    } catch (err) {
      setError('An error occurred while processing your payment.');
    }
    
    setProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElementContainer>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#424770',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      </CardElementContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <PaymentButton type="submit" disabled={!stripe || processing}>
        {processing ? 'Processing...' : `Pay USD ${parseFloat(amount).toFixed(2)}`}
      </PaymentButton>
    </form>
  );
};

const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { flight, passengers } = location.state || {};

  useEffect(() => {
    window.scrollTo(0, 0);
    
    if (!flight || !passengers) {
      navigate('/flights');
    }
  }, [flight, passengers, navigate]);

  const handlePaymentSuccess = () => {
    navigate('/payment-success');
  };

  if (!flight || !passengers) {
    return null;
  }

  return (
    <PageContainer>
      <MainContent>
        <PaymentTitle>Secure Payment</PaymentTitle>
        
        <Elements stripe={stripePromise}>
          <CheckoutForm 
            amount={flight.price}
            onSuccess={handlePaymentSuccess}
          />
        </Elements>

        <SecurityFeatures>
          <SecurityItem>
            <SecurityIcon>
              <FaLock />
            </SecurityIcon>
            <SecurityText>
              <h4>Secure Payment</h4>
              <p>Your payment information is encrypted and secure</p>
            </SecurityText>
          </SecurityItem>
          <SecurityItem>
            <SecurityIcon>
              <FaShieldAlt />
            </SecurityIcon>
            <SecurityText>
              <h4>Protected by Stripe</h4>
              <p>World-class security and fraud protection</p>
            </SecurityText>
          </SecurityItem>
        </SecurityFeatures>
      </MainContent>

      <SideContent>
        <FlightSummaryCard>
          <SummaryHeader>
            <h2>Flight Summary</h2>
          </SummaryHeader>
          <FlightDetails>
            <p>From: {flight.origin}</p>
            <p>To: {flight.destination}</p>
            <p>Date: {new Date(flight.departureDate).toLocaleDateString()}</p>
            <p>Passenger: {passengers.firstName} {passengers.lastName}</p>
            <p>Total: USD {parseFloat(flight.price).toFixed(2)}</p>
          </FlightDetails>
        </FlightSummaryCard>
      </SideContent>
    </PageContainer>
  );
};

export default PaymentPage;
