import React from 'react';
import styled from 'styled-components';
import { FaHotel, FaCalendarAlt, FaUsers } from 'react-icons/fa';

const Hotels = () => {
  return (
    <PageContainer>
      <HeroSection>
        <HeroOverlay>
          <h1>BOOK YOUR PERFECT STAY</h1>
          <p>Find and book the best hotels worldwide with Sandy Travel and Tourism Agency</p>
        </HeroOverlay>
      </HeroSection>

      <ContentSection>
        <WhyBookWithUs>
          <h2>Why Book With Us?</h2>
          <BenefitsGrid>
            <Benefit>
              <FaHotel />
              <h3>Best Hotel Selection</h3>
              <p>Access to thousands of hotels worldwide at competitive rates</p>
            </Benefit>
            <Benefit>
              <FaCalendarAlt />
              <h3>Flexible Booking</h3>
              <p>Easy modification and cancellation options</p>
            </Benefit>
            <Benefit>
              <FaUsers />
              <h3>Personal Assistance</h3>
              <p>24/7 support from our travel experts</p>
            </Benefit>
          </BenefitsGrid>
        </WhyBookWithUs>

        <BookingSection>
          <h2>Ready to Book Your Stay?</h2>
          <p>Let our travel experts help you find and book the perfect hotel for your needs.</p>
          <BookNowButton href="mailto:bookings@sandytravel.com">Book Hotel Now</BookNowButton>
        </BookingSection>
      </ContentSection>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const HeroSection = styled.div`
  height: 500px;
  background-image: url('/images/beautiful3.png');
  background-size: cover;
  background-position: center;
  position: relative;
`;

const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  padding: 20px;

  h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    max-width: 600px;
  }
`;

const ContentSection = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
`;

const WhyBookWithUs = styled.div`
  text-align: center;
  margin-bottom: 60px;

  h2 {
    color: #4682B4;
    margin-bottom: 40px;
    font-size: 2.5rem;
  }
`;

const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
`;

const Benefit = styled.div`
  padding: 30px;
  text-align: center;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  svg {
    font-size: 3rem;
    color: #4682B4;
    margin-bottom: 20px;
  }

  h3 {
    color: #4682B4;
    margin-bottom: 15px;
  }

  p {
    color: #666;
    line-height: 1.6;
  }
`;

const BookingSection = styled.div`
  text-align: center;
  padding: 60px 20px;
  background: #f5f5f5;
  border-radius: 8px;

  h2 {
    color: #4682B4;
    margin-bottom: 20px;
    font-size: 2rem;
  }

  p {
    color: #666;
    margin-bottom: 30px;
    font-size: 1.1rem;
  }
`;

const BookNowButton = styled.a`
  display: inline-block;
  background: #4682B4;
  color: white;
  padding: 15px 40px;
  border-radius: 30px;
  text-decoration: none;
  font-size: 1.2rem;
  transition: background 0.3s ease;

  &:hover {
    background: #3a6d96;
  }
`;

export default Hotels;
