import React from 'react';
import styled from 'styled-components';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaClock, FaWhatsapp } from 'react-icons/fa';

const Contact = () => {
  return (
    <PageContainer>
      <HeroSection>
        <HeroOverlay>
          <h1>CONTACT US</h1>
          <p>We're here to help plan your perfect journey</p>
        </HeroOverlay>
      </HeroSection>

      <ContentSection>
        <ContactInfo>
          <h2>Get in Touch</h2>
          <p>Have questions about our services? Our travel experts are ready to assist you.</p>
          
          <InfoGrid>
            <InfoCard>
              <FaPhone />
              <h3>Phone</h3>
              <p>(+231)886181918</p>
            </InfoCard>

            <InfoCard>
              <FaEnvelope />
              <h3>Email</h3>
              <p>coming soon</p>
            </InfoCard>

            <InfoCard>
              <FaMapMarkerAlt />
              <h3>Location</h3>
              <p>Camp Johnson Road</p>
              <p>Monrovia, Liberia</p>
            </InfoCard>

            <InfoCard>
              <FaClock />
              <h3>Business Hours</h3>
              <p>coming soon</p>
            </InfoCard>
          </InfoGrid>
        </ContactInfo>

        <GetInTouchSection>
          <h2>Ready to Start Your Journey?</h2>
          <p>Contact us now and let our travel experts help you plan your perfect trip.</p>
          <ButtonGroup>
            <ContactButton href="#">
              <FaEnvelope /> Travefy - (coming soon)
            </ContactButton>
            {/* <ContactButton href="https://wa.me/2315550123" className="whatsapp"> */}
            <ContactButton href="#" className="whatsapp">
              <FaWhatsapp /> WhatsApp - (coming soon)
            </ContactButton>
          </ButtonGroup>
        </GetInTouchSection>

        <ServicesSection>
          <h2>Our Services</h2>
          <ServicesList>
            <li>Air Ticket Bookings</li>
            <li>Hotel Reservations</li>
            <li>Holiday Packages</li>
            <li>Car Rentals</li>
            <li>Travel Insurance</li>
            <li>Visa Assistance</li>
            <li>Corporate Travel</li>
            <li>24/7 Travel Support</li>
          </ServicesList>
        </ServicesSection>
      </ContentSection>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const HeroSection = styled.div`
  height: 400px;
  background-image: url('/images/beautiful1.png');
  background-size: cover;
  background-position: center;
  position: relative;
`;

const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;

  h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
  }
`;

const ContentSection = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
`;

const ContactInfo = styled.div`
  text-align: center;
  margin-bottom: 60px;

  h2 {
    color: #4682B4;
    margin-bottom: 20px;
    font-size: 2.5rem;
  }

  > p {
    color: #666;
    margin-bottom: 40px;
    font-size: 1.1rem;
  }
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
`;

const InfoCard = styled.div`
  padding: 30px;
  text-align: center;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;

  svg {
    font-size: 2.5rem;
    color: #4682B4;
    margin-bottom: 20px;
  }

  h3 {
    color: #4682B4;
    margin-bottom: 15px;
  }

  p {
    color: #666;
    line-height: 1.6;
  }
`;

const GetInTouchSection = styled.div`
  text-align: center;
  padding: 60px 20px;
  background: #f5f5f5;
  border-radius: 8px;
  margin-bottom: 60px;

  h2 {
    color: #4682B4;
    margin-bottom: 20px;
    font-size: 2rem;
  }

  p {
    color: #666;
    margin-bottom: 30px;
    font-size: 1.1rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
`;

const ContactButton = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  background: #4682B4;
  color: white;
  padding: 15px 40px;
  border-radius: 30px;
  text-decoration: none;
  font-size: 1.2rem;
  transition: background 0.3s ease;

  &:hover {
    background: #3a6d96;
  }

  &.whatsapp {
    background: #25D366;

    &:hover {
      background: #1da851;
    }
  }

  svg {
    font-size: 1.4rem;
  }
`;

const ServicesSection = styled.div`
  text-align: center;

  h2 {
    color: #4682B4;
    margin-bottom: 30px;
    font-size: 2rem;
  }
`;

const ServicesList = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin: 0;

  li {
    color: #666;
    font-size: 1.1rem;
    padding: 15px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

export default Contact;
