import React from 'react';
import styled from 'styled-components';

const Services = () => {
  return (
    <PageContainer>
      <HeroSection>
        <HeroOverlay>
          <h1>SERVICES WE PROVIDE</h1>
        </HeroOverlay>
      </HeroSection>

      <ServicesContainer>
        <ServiceSection>
          <FullWidthServiceSection style={{ backgroundImage: `url('/images/banner4.png')` }} />
          <ServiceContent>
            <ServiceTitle>AIR TICKETS</ServiceTitle>
            <ServiceText>
              We offer competitive airfares for both domestic and international flights. Our partnerships with major airlines ensure you get the best deals and most convenient routes for your travel needs.
            </ServiceText>
          </ServiceContent>
        </ServiceSection>

        <ServiceSection>
          <FullWidthServiceSection style={{ backgroundImage: `url('/images/services2.jpg')` }} />
          <ServiceContent>
            <ServiceTitle>HOLIDAY PACKAGES</ServiceTitle>
            <ServiceText>
              Experience unforgettable vacations with our carefully curated holiday packages. From beach getaways to cultural explorations, we design perfect holiday experiences tailored to your preferences.
            </ServiceText>
          </ServiceContent>
        </ServiceSection>

        <ServiceSection>
          <FullWidthServiceSection style={{ backgroundImage: `url('/images/foreign1.png')` }} />
          <ServiceContent>
            <ServiceTitle>INTERNATIONAL HOTEL RESERVATIONS</ServiceTitle>
            <ServiceText>
              Find your perfect stay with our extensive network of international hotels. We offer accommodations ranging from luxury resorts to boutique hotels, ensuring comfort and value.
            </ServiceText>
          </ServiceContent>
        </ServiceSection>

        <ServiceSection>
          <FullWidthServiceSection style={{ backgroundImage: `url('/images/rental.png')` }} />
          <ServiceContent>
            <ServiceTitle>INTERNATIONAL CAR RENTAL RESERVATIONS</ServiceTitle>
            <ServiceText>
              Explore your destination with freedom. We provide car rental services worldwide, offering a wide range of vehicles to suit your needs and budget.
            </ServiceText>
          </ServiceContent>
        </ServiceSection>

        <ServiceSection>
          <FullWidthServiceSection style={{ backgroundImage: `url('/images/banner6.png')` }} />
          <ServiceContent>
            <ServiceTitle>CHARTER FLIGHTS</ServiceTitle>
            <ServiceText>
              Experience exclusive travel with our charter flight services. Perfect for group travel, special events, or when you need more flexibility in your travel schedule.
            </ServiceText>
          </ServiceContent>
        </ServiceSection>

        <ServiceSection>
          <FullWidthServiceSection style={{ backgroundImage: `url('/images/tour1.png')` }} />
          <ServiceContent>
            <ServiceTitle>EXCURSIONS</ServiceTitle>
            <ServiceText>
              Discover local attractions and adventures with our guided excursions. From city tours to adventure activities, we help you make the most of your destination.
            </ServiceText>
          </ServiceContent>
        </ServiceSection>

        <ServiceSection>
          <FullWidthServiceSection style={{ backgroundImage: `url('/images/insurance.png')` }} />
          <ServiceContent>
            <ServiceTitle>TRAVEL INSURANCE</ServiceTitle>
            <ServiceText>
              Travel with peace of mind. Our comprehensive travel insurance options cover medical emergencies, trip cancellations, lost baggage, and more.
            </ServiceText>
          </ServiceContent>
        </ServiceSection>

        <ServiceSection>
          <FullWidthServiceSection style={{ backgroundImage: `url('/images/visa1.png')` }} />
          <ServiceContent>
            <ServiceTitle>VISAS/E-VISAS ASSISTANCE</ServiceTitle>
            <ServiceText>
              Let us handle your visa requirements. We provide expert assistance with visa applications and e-visa services for various destinations.
            </ServiceText>
          </ServiceContent>
        </ServiceSection>

        <ServiceSection>
          <FullWidthServiceSection style={{ backgroundImage: `url('/images/yacht.png')` }} />
          <ServiceContent>
            <ServiceTitle>CRUISES</ServiceTitle>
            <ServiceText>
              Embark on unforgettable cruise adventures. We offer a wide selection of cruise packages across different destinations and cruise lines.
            </ServiceText>
          </ServiceContent>
        </ServiceSection>
      </ServicesContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const HeroSection = styled.div`
  height: 300px;
  background-image: url('/images/services1.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  margin-bottom: 40px;
`;

const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
  }
`;

const ServicesContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const ServiceSection = styled.div`
  margin-bottom: 60px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FullWidthServiceSection = styled.div`
  height: 300px;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  margin: 60px 0;
  width: 100vw;
  left: 50%;
  margin-left: -50vw;
`;

const ServiceContent = styled.div`
  padding: 40px;
`;

const ServiceTitle = styled.h2`
  font-size: 2rem;
  color: #4682B4;
  margin-bottom: 20px;
  text-align: center;
`;

const ServiceText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
`;

export default Services;
