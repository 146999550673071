import React from 'react';
import styled from 'styled-components';
import { FaPlane } from 'react-icons/fa';

const AboutUsPage = () => {
  const values = [
    {
      title: "Customer-Centricity",
      description: "Our clients are at the core of what we do; we deliver what they need and build satisfaction and trust."
    },
    {
      title: "Integrity",
      description: "The primary values that are characteristic of our work are truthfulness and openness."
    },
    {
      title: "Excellence",
      description: "Our goal and mission are to achieve the best quality in all the services we provide."
    },
    {
      title: "Innovation",
      description: "We have always valued creativity and technology in improving different travel experiences."
    },
    {
      title: "Sustainability",
      description: "Desiring to promote responsible tourism that preserves the environment and supports local communities."
    },
    {
      title: "Teamwork",
      description: "We collaborate, we respect each other, and we get great things done together."
    }
  ];

  return (
    <PageContainer>
      <HeroSection>
        <HeroOverlay>
          <PlaneImage>
            <FaPlane />
          </PlaneImage>
          <h1>ABOUT US</h1>
        </HeroOverlay>
      </HeroSection>

      <AboutContent>
        <AboutText>
          Sandy Travel and Tourism Agency is efficient in delivering travel services in and out of Monrovia, Liberia, and was established in 2024. We provide customers with tailor-made services with particular emphasis on providing product solutions that are ideal. These services include the planning of travel itineraries, hotel reservations, transport and associated services, guided tours, travel insurance, visa services and customer support. With our intimate comprehension of various travel needs, we open doors for everyone, helping to transform dreams into destinations.
        </AboutText>
      </AboutContent>

      <CommitmentSection>
        <CommitmentOverlay>
          <h2>WE ARE COMMITTED FOR BEST SERVICES</h2>
        </CommitmentOverlay>
      </CommitmentSection>

      <ContentGrid>
        <MissionSection>
          <MissionImage src="/images/mission1.png" alt="Our Mission" />
          <MissionContent>
            <MissionTitle>Our Mission</MissionTitle>
            <MissionText>
              To promote and create vivid, exciting, and unique travel experiences by offering unique, convenient travel services. It is our mission to continuously overachieve customer satisfaction through a friendly attitude, outstanding professionalism, and enthusiasm about our beautiful planet.
            </MissionText>
          </MissionContent>
        </MissionSection>

        <VisionSection>
          <VisionContent>
            <VisionTitle>Our Vision</VisionTitle>
            <VisionText>
              To emerge as one of the premier professional travel and tourism agencies in Liberia and in other parts of the world because of top-notch service delivery, guaranteed service delivery, and customer satisfaction. Our goal is to help people discover the rich potential of global tourism and show them how to do it consciously and meaningfully.
            </VisionText>
          </VisionContent>
          <VisionImage src="/images/vision3.png" alt="Our Vision" />
        </VisionSection>

        <ValuesSection>
          <ValuesImage src="/images/values1.png" alt="Our Values" />
          <ValuesContent>
            <ValuesTitle>Our Values</ValuesTitle>
            <ValuesList>
              {values.map((value, index) => (
                <li key={index}>
                  <strong>{value.title}</strong>
                  <p>{value.description}</p>
                </li>
              ))}
            </ValuesList>
          </ValuesContent>
        </ValuesSection>
      </ContentGrid>

      <ServicesSection>
        <ServicesTitle>Why Choose Our Luxury Services</ServicesTitle>
        <ServicesGrid>
          <ServiceCard bg="/images/agent3.png">
            <ServiceText>UNIQUELY YOU</ServiceText>
          </ServiceCard>
          <ServiceCard bg="/images/agent2.png">
            <ServiceText>PERSONAL ATTENTION</ServiceText>
          </ServiceCard>
          <ServiceCard bg="/images/agents1.png">
            <ServiceText>KNOWLEDGEABLE & PASSIONATE</ServiceText>
          </ServiceCard>
        </ServicesGrid>
      </ServicesSection>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

const HeroSection = styled.div`
  height: 400px;
  background-image: url('/images/banner4.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
`;

const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background: rgba(0, 0, 0, 0.5);

  h1 {
    font-size: 3.5rem;
    margin: 0;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
`;

const PlaneImage = styled.div`
  font-size: 4rem;
  margin-bottom: 1rem;
`;

const AboutContent = styled.div`
  padding: 60px 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const AboutText = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  color: #333;
  text-align: left;
  max-width: 1200px;
  margin: 0 auto;
`;

const CommitmentSection = styled.div`
  height: 300px;
  background-image: url('/images/beautiful4.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  margin: 60px 0;
`;

const CommitmentOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);

  h2 {
    color: white;
    font-size: 2.5rem;
    margin: 0;
    text-align: center;
    padding: 0 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
`;

const ContentGrid = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 60px 20px;
`;

const MissionSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 60px;
`;

const MissionImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
`;

const MissionContent = styled.div`
  padding-left: 30px;
`;

const MissionTitle = styled.h2`
  color: #FF0000;
  margin-bottom: 20px;
`;

const MissionText = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  text-align: left;
`;

const VisionSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 60px;
`;

const VisionContent = styled.div`
  padding-right: 30px;
`;

const VisionTitle = styled.h2`
  color: #FF0000;
  margin-bottom: 20px;
`;

const VisionText = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  text-align: left;
`;

const VisionImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
`;

const ValuesSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
`;

const ValuesImage = styled.img`
  width: 100%;
  height: 600px;
  object-fit: cover;
`;

const ValuesContent = styled.div`
  padding-left: 30px;
`;

const ValuesTitle = styled.h2`
  color: #FF0000;
  margin-bottom: 20px;
`;

const ValuesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;

  li {
    margin-bottom: 20px;
    
    p {
      margin: 5px 0 0;
      font-size: 1.1rem;
      line-height: 1.6;
    }
  }
`;

const ServicesSection = styled.div`
  padding: 60px 20px;
  text-align: center;
  background-color: #f9f9f9;
`;

const ServicesTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const ServiceCard = styled.div`
  aspect-ratio: 1;
  position: relative;
  background-image: url(${props => props.bg});
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ServiceText = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  font-weight: bold;
  font-size: 1.1rem;
`;

export default AboutUsPage;
