// Color palette for the website
export const colors = {
    primary: '#FFFFFF',    // White - Primary color for backgrounds, headers, menus
    secondary: '#4682B4',  // Steel Blue - Secondary color for general content
    accent: '#FF0000',     // Red - Accent color for important highlights
    text: '#333333',       // Dark gray for text
    lightBlue: '#E6F0FF',  // Light blue for backgrounds
    darkBlue: '#003D99',   // Darker blue for hover states
    lightGray: '#F5F5F5',  // Light gray for sections
    gray: '#666666'        // Gray for secondary text
};
