import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from '../../styles/colors';

const FooterContainer = styled.footer`
  background-color: ${colors.secondary};
  color: ${colors.primary};
  padding: 4rem 2rem 2rem 2rem;
  margin-top: auto;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
`;

const FooterSection = styled.div`
  h3 {
    color: ${colors.primary};
    margin-bottom: 1.5rem;
  }
`;

const FooterLink = styled(Link)`
  color: ${colors.primary};
  text-decoration: none;
  display: block;
  margin-bottom: 0.5rem;
  
  &:hover {
    text-decoration: underline;
  }
`;

const ContactInfo = styled.div`
  margin-bottom: 0.5rem;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const Copyright = styled.div`
  text-align: center;
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterSection>
          <h3>Sandy Travel Tours</h3>
          <p>Your trusted partner in creating unforgettable travel experiences.</p>
          <SocialLinks>
            {/* <FooterLink as="a" href="#" target="_blank">Facebook</FooterLink> */}
            <FooterLink as="a" href="#" target="_blank">Instagram</FooterLink>
            {/* <FooterLink as="a" href="#" target="_blank">Twitter</FooterLink> */}
          </SocialLinks>
        </FooterSection>

        <FooterSection>
          <h3>Quick Links</h3>
          <FooterLink to="/about">About Us</FooterLink>
          <FooterLink to="/services">Our Services</FooterLink>
        </FooterSection>

        <FooterSection>
          <h3>Contact Us</h3>
          <ContactInfo>Email: coming soon</ContactInfo>
          <ContactInfo>Phone: (+231)886181918</ContactInfo>
          <ContactInfo>Address: Camp Johnson Road</ContactInfo>
        </FooterSection>

        <FooterSection>
          <h3>Update</h3>
          <p>Follow SandyTravelTours on instagram for even more traval updates!</p>
          {/* Add newsletter form in future update */}
        </FooterSection>
      </FooterContent>
      
      <Copyright>
        {new Date().getFullYear()} Sandy Travel Tours. All rights reserved.
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;
