import React, { useState, useRef, useEffect } from 'react';
import styles from './PassengerClassSelect.module.css';
import { FaMinus, FaPlus } from 'react-icons/fa';

const PassengerClassSelect = ({ onClose, onChange }) => {
  const [selectedClass, setSelectedClass] = useState('Economy');
  const [passengers, setPassengers] = useState({
    adult: 1,
    child: 0,
    infant: 0
  });
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  const handleClassSelect = (travelClass) => {
    setSelectedClass(travelClass);
    onChange({ class: travelClass, passengers });
  };

  const updatePassengers = (type, increment) => {
    const newPassengers = {
      ...passengers,
      [type]: Math.max(0, passengers[type] + (increment ? 1 : -1))
    };
    setPassengers(newPassengers);
    onChange({ class: selectedClass, passengers: newPassengers });
  };

  const getTotalPassengers = () => {
    return passengers.adult + passengers.child + passengers.infant;
  };

  const getDisplayText = () => {
    return `${getTotalPassengers()} Traveller${getTotalPassengers() > 1 ? 's' : ''}, ${selectedClass}`;
  };

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <div className={styles.classSelection}>
        <button 
          className={`${styles.classOption} ${selectedClass === 'Economy' ? styles.active : ''}`}
          onClick={() => handleClassSelect('Economy')}
        >
          Economy
        </button>
        <button 
          className={`${styles.classOption} ${selectedClass === 'Premium Economy' ? styles.active : ''}`}
          onClick={() => handleClassSelect('Premium Economy')}
        >
          Premium Economy
        </button>
        <button 
          className={`${styles.classOption} ${selectedClass === 'Business Class' ? styles.active : ''}`}
          onClick={() => handleClassSelect('Business Class')}
        >
          Business Class
        </button>
        <button 
          className={`${styles.classOption} ${selectedClass === 'First Class' ? styles.active : ''}`}
          onClick={() => handleClassSelect('First Class')}
        >
          First Class
        </button>
      </div>

      <div className={styles.passengerSection}>
        <h3>Number of Travellers</h3>
        
        <div className={styles.passengerType}>
          <div className={styles.passengerInfo}>
            <span>Adult</span>
            <span className={styles.ageInfo}>(12 Years)</span>
          </div>
          <div className={styles.counter}>
            <button 
              onClick={() => updatePassengers('adult', false)}
              disabled={passengers.adult <= 1}
            >
              <FaMinus />
            </button>
            <span>{passengers.adult}</span>
            <button onClick={() => updatePassengers('adult', true)}>
              <FaPlus />
            </button>
          </div>
        </div>

        <div className={styles.passengerType}>
          <div className={styles.passengerInfo}>
            <span>Child</span>
            <span className={styles.ageInfo}>(2-11 years)</span>
          </div>
          <div className={styles.counter}>
            <button 
              onClick={() => updatePassengers('child', false)}
              disabled={passengers.child <= 0}
            >
              <FaMinus />
            </button>
            <span>{passengers.child}</span>
            <button onClick={() => updatePassengers('child', true)}>
              <FaPlus />
            </button>
          </div>
        </div>

        <div className={styles.passengerType}>
          <div className={styles.passengerInfo}>
            <span>Infant</span>
            <span className={styles.ageInfo}>(Under 2 years)</span>
          </div>
          <div className={styles.counter}>
            <button 
              onClick={() => updatePassengers('infant', false)}
              disabled={passengers.infant <= 0}
            >
              <FaMinus />
            </button>
            <span>{passengers.infant}</span>
            <button onClick={() => updatePassengers('infant', true)}>
              <FaPlus />
            </button>
          </div>
        </div>
      </div>

      <div className={styles.actions}>
        <button className={styles.resetBtn} onClick={() => {
          setPassengers({ adult: 1, child: 0, infant: 0 });
          setSelectedClass('Economy');
        }}>
          Reset
        </button>
        <button className={styles.applyBtn} onClick={onClose}>
          Apply
        </button>
      </div>
    </div>
  );
};

export default PassengerClassSelect;
