import { AFRICAN_AIRPORTS } from '../data/africanAirports';

const BASE_URL = 'https://test.api.amadeus.com/v2';

// Function to get the access token
const getAccessToken = async () => {
    const response = await fetch('https://test.api.amadeus.com/v1/security/oauth2/token', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
            grant_type: 'client_credentials',
            client_id: process.env.REACT_APP_AMADEUS_CLIENT_ID,
            client_secret: process.env.REACT_APP_AMADEUS_CLIENT_SECRET
        })
    });

    if (!response.ok) {
        throw new Error('Failed to get access token');
    }

    const data = await response.json();
    return data.access_token;
};

export const searchFlights = async (searchParams) => {
    try {
        const { 
            originLocationCode, 
            destinationLocationCode, 
            departureDate,
            returnDate,
            tripType = 'oneWay', 
            adults = 1, 
            travelClass = 'ECONOMY' 
        } = searchParams;

        if (!originLocationCode || !destinationLocationCode || !departureDate) {
            throw new Error('Origin, destination, and departure date are required');
        }

        if (tripType === 'roundTrip' && !returnDate) {
            throw new Error('Return date is required for round-trip flights');
        }

        // Get access token
        const accessToken = await getAccessToken();

        // Prepare search body according to v2 API
        const searchBody = {
            currencyCode: 'USD',
            originDestinations: [
                {
                    id: '1',
                    originLocationCode: originLocationCode,
                    destinationLocationCode: destinationLocationCode,
                    departureDateTimeRange: {
                        date: departureDate
                    }
                }
            ],
            travelers: [
                {
                    id: '1',
                    travelerType: 'ADULT',
                    fareOptions: ['STANDARD']
                }
            ],
            sources: ['GDS'],
            searchCriteria: {
                maxFlightOffers: 50,
                flightFilters: {
                    cabinRestrictions: [
                        {
                            cabin: travelClass,
                            coverage: 'MOST_SEGMENTS',
                            originDestinationIds: ['1']
                        }
                    ]
                }
            }
        };

        // Add return flight for round trips
        if (tripType === 'roundTrip' && returnDate) {
            searchBody.originDestinations.push({
                id: '2',
                originLocationCode: destinationLocationCode,
                destinationLocationCode: originLocationCode,
                departureDateTimeRange: {
                    date: returnDate
                }
            });

            // Update cabin restrictions for return flight
            searchBody.searchCriteria.flightFilters.cabinRestrictions[0].originDestinationIds.push('2');
        }

        // Add additional adult travelers if needed
        for (let i = 1; i < Number(adults); i++) {
            searchBody.travelers.push({
                id: String(i + 1),
                travelerType: 'ADULT',
                fareOptions: ['STANDARD']
            });
        }

        console.log('Searching flights with params:', searchBody);

        // Make the flight search request
        const response = await fetch(`${BASE_URL}/shopping/flight-offers`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(searchBody)
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('API Error:', errorData);
            throw new Error(errorData.errors?.[0]?.detail || 'Failed to search flights');
        }

        const data = await response.json();
        console.log('API Response:', data);
        
        // Add tripType to each flight offer
        const flightOffers = data.data.map(offer => ({
            ...offer,
            tripType: tripType
        }));

        console.log('Processed Flight Offers:', flightOffers);
        return flightOffers;
    } catch (error) {
        console.error('Error searching flights:', error);
        throw error;
    }
};

// Comprehensive list of African country codes
const AFRICAN_COUNTRY_CODES = [
    'DZ', 'AO', 'BJ', 'BW', 'BF', 'BI', 'CM', 'CV', 'CF', 'TD', 'KM', 'CG', 
    'CD', 'DJ', 'EG', 'GQ', 'ER', 'ET', 'GA', 'GM', 'GH', 'GN', 'GW', 'CI', 
    'KE', 'LS', 'LR', 'LY', 'MG', 'MW', 'ML', 'MR', 'MU', 'MA', 'MZ', 'NA', 
    'NE', 'NG', 'RW', 'SN', 'SC', 'SL', 'SO', 'ZA', 'SS', 'SD', 'SZ', 'TZ', 
    'TG', 'TN', 'UG', 'ZM', 'ZW'
];

export const searchAirportsByCityOrCountry = async (keyword) => {
    try {
        const token = await getAccessToken();
        
        // Validate keyword length
        if (keyword.length < 2) {
            console.warn('Keyword too short for search');
            return [];
        }

        let allResults = [];

        // Search strategies
        const searchStrategies = [
            // Strategy 1: Direct location search
            async () => {
                const url = new URL('https://test.api.amadeus.com/v1/reference-data/locations');
                url.searchParams.append('keyword', keyword);
                url.searchParams.append('subType', 'AIRPORT');
                url.searchParams.append('page[limit]', '20');

                const response = await fetch(url.toString(), {
                    method: 'GET',
                    headers: { 
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) return [];
                const data = await response.json();
                return data.data || [];
            },

            // Strategy 2: Search by African country codes
            async () => {
                // Find matching country codes based on keyword
                const matchingCountryCodes = AFRICAN_COUNTRY_CODES.filter(code => 
                    keyword.toLowerCase() === code.toLowerCase() ||
                    keyword.toLowerCase().includes(code.toLowerCase())
                );

                // If no matching country codes, return empty
                if (matchingCountryCodes.length === 0) return [];

                const results = [];
                for (const countryCode of matchingCountryCodes) {
                    const url = new URL('https://test.api.amadeus.com/v1/reference-data/locations');
                    url.searchParams.append('countryCode', countryCode);
                    url.searchParams.append('subType', 'AIRPORT');
                    url.searchParams.append('page[limit]', '20');

                    const response = await fetch(url.toString(), {
                        method: 'GET',
                        headers: { 
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json'
                        }
                    });

                    if (!response.ok) continue;
                    const data = await response.json();
                    results.push(...(data.data || []));
                }

                return results;
            },

            // Strategy 3: City search
            async () => {
                const url = new URL('https://test.api.amadeus.com/v1/reference-data/locations/cities');
                url.searchParams.append('keyword', keyword);
                url.searchParams.append('countryCode', AFRICAN_COUNTRY_CODES.join(','));
                url.searchParams.append('page[limit]', '20');

                const response = await fetch(url.toString(), {
                    method: 'GET',
                    headers: { 
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (!response.ok) return [];
                const data = await response.json();
                return data.data || [];
            }
        ];

        // Execute search strategies
        for (const strategy of searchStrategies) {
            const results = await strategy();
            
            // Process results
            const processedResults = results.map((location) => ({
                id: location.id,
                name: location.name,
                iataCode: location.iataCode || 
                    (location.airports && location.airports.length > 0 ? location.airports[0].iataCode : ''),
                city: location.address?.cityName || location.name,
                country: location.address?.countryName || 'Unknown Country',
            })).filter(airport => 
                airport.iataCode && 
                (
                    keyword.toLowerCase() === airport.city.toLowerCase() ||
                    keyword.toLowerCase() === airport.country.toLowerCase() ||
                    airport.city.toLowerCase().includes(keyword.toLowerCase()) ||
                    airport.country.toLowerCase().includes(keyword.toLowerCase()) ||
                    AFRICAN_COUNTRY_CODES.some(code => 
                        airport.country.toLowerCase().includes(code.toLowerCase())
                    )
                )
            );

            // Add unique results
            processedResults.forEach(result => {
                if (!allResults.some(existing => existing.iataCode === result.iataCode)) {
                    allResults.push(result);
                }
            });

            // Break if we have results
            if (allResults.length > 0) break;
        }

        // Sort results to prioritize exact matches
        allResults.sort((a, b) => {
            const aMatchesExactly = a.city.toLowerCase() === keyword.toLowerCase() || 
                                    a.country.toLowerCase() === keyword.toLowerCase();
            const bMatchesExactly = b.city.toLowerCase() === keyword.toLowerCase() || 
                                    b.country.toLowerCase() === keyword.toLowerCase();
            return aMatchesExactly ? -1 : bMatchesExactly ? 1 : 0;
        });

        // Log and return results
        console.log('African Airport Search Results:', allResults);
        return allResults.slice(0, 20);
    } catch (error) {
        console.error('Complete Error in searchAirportsByCityOrCountry:', {
            message: error.message,
            stack: error.stack
        });
        return [];
    }
};

// Function to search airports locally
export const searchLocalAirports = (keyword) => {
    if (keyword.length < 2) return [];

    const lowercaseKeyword = keyword.toLowerCase();
    return AFRICAN_AIRPORTS.filter(airport => 
        airport.city.toLowerCase().includes(lowercaseKeyword) ||
        airport.country.toLowerCase().includes(lowercaseKeyword) ||
        airport.name.toLowerCase().includes(lowercaseKeyword) ||
        airport.iataCode.toLowerCase().includes(lowercaseKeyword)
    );
};
