export const AFRICAN_AIRPORTS = [
    // Ghana Airports
    { iataCode: 'ACC', name: 'Kotoka International Airport', city: 'Accra', country: 'Ghana' },
    { iataCode: 'KMS', name: 'Kumasi Airport', city: 'Kumasi', country: 'Ghana' },
    { iataCode: 'TML', name: 'Tamale Airport', city: 'Tamale', country: 'Ghana' },
    { iataCode: 'TKD', name: 'Takoradi Airport', city: 'Takoradi', country: 'Ghana' },

    // Nigeria Airports
    { iataCode: 'LOS', name: 'Murtala Muhammed International Airport', city: 'Lagos', country: 'Nigeria' },
    { iataCode: 'ABV', name: 'Nnamdi Azikiwe International Airport', city: 'Abuja', country: 'Nigeria' },
    { iataCode: 'KAN', name: 'Mallam Aminu Kano International Airport', city: 'Kano', country: 'Nigeria' },
    { iataCode: 'PHC', name: 'Port Harcourt International Airport', city: 'Port Harcourt', country: 'Nigeria' },

    // Kenya Airports
    { iataCode: 'NBO', name: 'Jomo Kenyatta International Airport', city: 'Nairobi', country: 'Kenya' },
    { iataCode: 'MBA', name: 'Moi International Airport', city: 'Mombasa', country: 'Kenya' },
    { iataCode: 'KIS', name: 'Kisumu International Airport', city: 'Kisumu', country: 'Kenya' },

    // South Africa Airports
    { iataCode: 'JNB', name: 'O.R. Tambo International Airport', city: 'Johannesburg', country: 'South Africa' },
    { iataCode: 'CPT', name: 'Cape Town International Airport', city: 'Cape Town', country: 'South Africa' },
    { iataCode: 'DUR', name: 'King Shaka International Airport', city: 'Durban', country: 'South Africa' },
    { iataCode: 'PLZ', name: 'Chief Dawid Stuurman International Airport', city: 'Port Elizabeth', country: 'South Africa' },

    // Egypt Airports
    { iataCode: 'CAI', name: 'Cairo International Airport', city: 'Cairo', country: 'Egypt' },
    { iataCode: 'HRG', name: 'Hurghada International Airport', city: 'Hurghada', country: 'Egypt' },
    { iataCode: 'SSH', name: 'Sharm El Sheikh International Airport', city: 'Sharm El Sheikh', country: 'Egypt' },

    // Morocco Airports
    { iataCode: 'CMN', name: 'Mohammed V International Airport', city: 'Casablanca', country: 'Morocco' },
    { iataCode: 'RAK', name: 'Marrakech Menara Airport', city: 'Marrakech', country: 'Morocco' },
    { iataCode: 'FEZ', name: 'Fès–Saïs Airport', city: 'Fès', country: 'Morocco' },
    { iataCode: 'TNG', name: 'Tangier Ibn Battouta Airport', city: 'Tangier', country: 'Morocco' },

    // Ethiopia Airports
    { iataCode: 'ADD', name: 'Bole International Airport', city: 'Addis Ababa', country: 'Ethiopia' },
    { iataCode: 'JIM', name: 'Aba Segud Airport', city: 'Jimma', country: 'Ethiopia' },

    // Tanzania Airports
    { iataCode: 'DAR', name: 'Julius Nyerere International Airport', city: 'Dar es Salaam', country: 'Tanzania' },
    { iataCode: 'ZNZ', name: 'Abeid Amani Karume International Airport', city: 'Zanzibar', country: 'Tanzania' },
    { iataCode: 'JRO', name: 'Kilimanjaro International Airport', city: 'Kilimanjaro', country: 'Tanzania' },

    // Liberia Airports
    { iataCode: 'ROB', name: 'Roberts International Airport', city: 'Monrovia', country: 'Liberia' },
    { iataCode: 'MLW', name: 'Spriggs Payne Airport', city: 'Monrovia', country: 'Liberia' },

    // Algeria Airports
    { iataCode: 'ALG', name: 'Houari Boumediene Airport', city: 'Algiers', country: 'Algeria' },
    { iataCode: 'ORN', name: 'Ahmed Ben Bella Airport', city: 'Oran', country: 'Algeria' },
    { iataCode: 'CZL', name: 'Mohamed Boudiaf International Airport', city: 'Constantine', country: 'Algeria' },
    { iataCode: 'TMR', name: 'Aguenar – Hadj Bey Akhamok Airport', city: 'Tamanrasset', country: 'Algeria' },

    // Angola Airports
    { iataCode: 'LAD', name: 'Quatro de Fevereiro Airport', city: 'Luanda', country: 'Angola' },
    { iataCode: 'LUB', name: 'Lubango Airport', city: 'Lubango', country: 'Angola' },

    // Benin Airports
    { iataCode: 'COO', name: 'Cadjehoun Airport', city: 'Cotonou', country: 'Benin' },

    // Botswana Airports
    { iataCode: 'GBE', name: 'Sir Seretse Khama International Airport', city: 'Gaborone', country: 'Botswana' },

    // Burkina Faso Airports
    { iataCode: 'OUA', name: 'Ouagadougou Airport', city: 'Ouagadougou', country: 'Burkina Faso' },

    // Burundi Airports
    { iataCode: 'BJM', name: 'Bujumbura International Airport', city: 'Bujumbura', country: 'Burundi' },

    // Cameroon Airports
    { iataCode: 'NSI', name: 'Yaoundé Nsimalen International Airport', city: 'Yaoundé', country: 'Cameroon' },
    { iataCode: 'DLA', name: 'Douala International Airport', city: 'Douala', country: 'Cameroon' },

    // Cape Verde Airports
    { iataCode: 'RAI', name: 'Nelson Mandela International Airport', city: 'Praia', country: 'Cape Verde' },

    // Central African Republic Airports
    { iataCode: 'BGF', name: 'Bangui M\'Poko International Airport', city: 'Bangui', country: 'Central African Republic' },

    // Chad Airports
    { iataCode: 'NDJ', name: 'N\'Djamena International Airport', city: 'N\'Djamena', country: 'Chad' },

    // Comoros Airports
    { iataCode: 'HAH', name: 'Prince Said Ibrahim International Airport', city: 'Moroni', country: 'Comoros' },

    // Congo (Democratic Republic) Airports
    { iataCode: 'FIH', name: 'N\'Djili Airport', city: 'Kinshasa', country: 'Congo (DRC)' },
    { iataCode: 'FBM', name: 'Lubumbashi International Airport', city: 'Lubumbashi', country: 'Congo (DRC)' },

    // Congo (Republic) Airports
    { iataCode: 'BZV', name: 'Maya-Maya Airport', city: 'Brazzaville', country: 'Congo (Republic)' },

    // Djibouti Airports
    { iataCode: 'JIB', name: 'Djibouti–Ambouli International Airport', city: 'Djibouti', country: 'Djibouti' },

    // Equatorial Guinea Airports
    { iataCode: 'SSG', name: 'Malabo International Airport', city: 'Malabo', country: 'Equatorial Guinea' },

    // Eswatini (Swaziland) Airports
    { iataCode: 'SHO', name: 'King Mswati III International Airport', city: 'Manzini', country: 'Eswatini' },

    // Gabon Airports
    { iataCode: 'LBV', name: 'Libreville Leon M\'ba International Airport', city: 'Libreville', country: 'Gabon' },

    // Gambia Airports
    { iataCode: 'BJL', name: 'Banjul International Airport', city: 'Banjul', country: 'Gambia' },

    // Guinea Airports
    { iataCode: 'CKY', name: 'Conakry International Airport', city: 'Conakry', country: 'Guinea' },

    // Guinea-Bissau Airports
    { iataCode: 'OXB', name: 'Osvaldo Vieira International Airport', city: 'Bissau', country: 'Guinea-Bissau' },

    // Lesotho Airports
    { iataCode: 'MSU', name: 'Moshoeshoe I International Airport', city: 'Maseru', country: 'Lesotho' },

    // Madagascar Airports
    { iataCode: 'TNR', name: 'Ivato International Airport', city: 'Antananarivo', country: 'Madagascar' },

    // Malawi Airports
    { iataCode: 'LLW', name: 'Lilongwe International Airport', city: 'Lilongwe', country: 'Malawi' },

    // Mali Airports
    { iataCode: 'BKO', name: 'Modibo Keita International Airport', city: 'Bamako', country: 'Mali' },

    // Mauritania Airports
    { iataCode: 'NKC', name: 'Nouakchott International Airport', city: 'Nouakchott', country: 'Mauritania' },

    // Mauritius Airports
    { iataCode: 'MRU', name: 'Sir Seewoosagur Ramgoolam International Airport', city: 'Port Louis', country: 'Mauritius' },

    // Mozambique Airports
    { iataCode: 'MPM', name: 'Maputo International Airport', city: 'Maputo', country: 'Mozambique' },

    // Namibia Airports
    { iataCode: 'WDH', name: 'Hosea Kutako International Airport', city: 'Windhoek', country: 'Namibia' },

    // Rwanda Airports
    { iataCode: 'KGL', name: 'Kigali International Airport', city: 'Kigali', country: 'Rwanda' },

    // Seychelles Airports
    { iataCode: 'SEZ', name: 'Seychelles International Airport', city: 'Victoria', country: 'Seychelles' },

    // Sierra Leone Airports
    { iataCode: 'FNA', name: 'Lungi International Airport', city: 'Freetown', country: 'Sierra Leone' },

    // Somalia Airports
    { iataCode: 'MGQ', name: 'Aden Adde International Airport', city: 'Mogadishu', country: 'Somalia' },

    // Sudan Airports
    { iataCode: 'KRT', name: 'Khartoum International Airport', city: 'Khartoum', country: 'Sudan' },
    { iataCode: 'PZU', name: 'Port Sudan New International Airport', city: 'Port Sudan', country: 'Sudan' },

    // Togo Airports
    { iataCode: 'LFW', name: 'Gnassingbé Eyadéma International Airport', city: 'Lomé', country: 'Togo' },

    // Tunisia Airports
    { iataCode: 'TUN', name: 'Tunis–Carthage International Airport', city: 'Tunis', country: 'Tunisia' },

    // Uganda Airports
    { iataCode: 'SRT', name: 'Soroti Airport', city: 'Soroti', country: 'Uganda' },

    // Zambia Airports
    { iataCode: 'LUN', name: 'Kenneth Kaunda International Airport', city: 'Lusaka', country: 'Zambia' },
    { iataCode: 'NLA', name: 'Simon Mwansa Kapwepwe International Airport', city: 'Ndola', country: 'Zambia' },

    // Zimbabwe Airports
    { iataCode: 'HRE', name: 'Robert Gabriel Mugabe International Airport', city: 'Harare', country: 'Zimbabwe' },
    { iataCode: 'VFA', name: 'Victoria Falls Airport', city: 'Victoria Falls', country: 'Zimbabwe' },
];
